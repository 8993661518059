<template>
    <div class="text-right">
        <div v-if="opcionExistencia === 1">
            <b-icon v-if="item.existencia > 0"
                icon="cart-plus" 
                class="mr-6 icon-scale"
                v-b-tooltip.hover 
                title="Agregar" 
                :style="cssVars" 
                @click.prevent="$parent.$emit('add-item', item)"
                scale="2"></b-icon>
            <b-icon v-else
                icon="dash-circle" 
                class="mr-6" 
                v-b-tooltip.hover 
                title="Producto Agotado" 
                variant="danger"
                :style="cssVars" scale="2">
            </b-icon>
            <b-icon
                icon="info-circle-fill" 
                class="icon-info" 
                v-b-tooltip.hover 
                title="Más información" 
                :style="cssVars" scale="2" 
                @click.prevent="$parent.$emit('item-info', item)">
            </b-icon>
        </div>
        <div v-else>
            <b-icon
                icon="cart-plus" 
                class="mr-6 icon-scale"
                v-b-tooltip.hover 
                title="Agregar" 
                :style="cssVars" 
                @click.prevent="$parent.$emit('add-item', item)"
                scale="2"></b-icon>
            <b-icon
                icon="info-circle-fill" 
                class="icon-info" 
                v-b-tooltip.hover 
                title="Más información" 
                :style="cssVars" scale="2" 
                @click.prevent="$parent.$emit('item-info', item)">
            </b-icon>
        </div>
    </div>
</template>

<script>
import { mapState} from 'vuex'
export default {
    name: "CardFooter",
    props: {
        item: {
            type: Object
        }       
    },
    computed: {
        ...mapState('configuration', ['background', 'opcionExistencia']),
        cssVars() {
            return {
                '--border-bottom': this.background
            };
        },
    },

};

</script>
<style scoped>

.icon-scale{
    transition: transform .4s;
    cursor: pointer;
}
.icon-scale:hover{
    transform: scale(1.2);
    color: var(--border-bottom);
}
.icon-info{
    color: var(--border-bottom);
    cursor: pointer;
}



</style>