<template>
    <b-row> 
        <b-card-group class="col-12" v-for="(item, index) of items" :key="index" >
            <b-card class="shadow-lg my-3">
                <b-row no-gutters>
                    <b-col class="col-sm-2 d-none d-sm-block">
                        <b-card-img :src="item.imagen" alt="Image" class="mb-5" style="cursor: pointer" @click.prevent="$emit('item-info', item)"></b-card-img>
                    </b-col>
                    <b-col class="col-12 col-sm-8">
                        <b-card-text>
                            <a class="brown--text pb-5" @click.prevent="$emit('item-info', item)">{{item.nombre}}</a> <br>
                            <div class="item-detail">
                                Item: {{item.codigo}} <br>
                                Código de Barras: <strong> {{item.codigo_barras}} </strong> <br>
                                <div v-if="opcionExistencia === 1">Cantidad Disponible: {{numberFormat(item.existencia)}} </div>
                            </div>
                        </b-card-text>
                    </b-col>
                    <b-col class="col-12 col-sm-2">
                        <b-card-text >
                            Precio: <span class="text-warning price-size"> ${{numberFormat(item.precio)}} </span>
                            <div class="producto-agotado" v-if="item.existencia === 0 && opcionExistencia === 1"><strong>Producto Agotado</strong></div>
                        </b-card-text>
                    </b-col>
                </b-row>
                <template #footer>
                    <Footer :item="item" />
                </template>
            </b-card>
        </b-card-group>
    </b-row>
</template>

<script>
import Footer from './Footer.vue'
import { mapState} from 'vuex'
export default {
    name: "CardItem3",
    props: {
        items: {
            type: Array
        }       
    },
    computed: {
        ...mapState('configuration', ['opcionExistencia']),
        ...mapState('auth', ['user']),
    },
    components: {
        Footer
    },

};

</script>
<style scoped>
.card-img { 
    max-width: 100%; 
    max-height: 150px;
    object-fit: scale-down;
}

.card-text {
    font-size: 14px;
}

.producto-agotado{
    font-size: 18px;
    color: red;
}
.price-size{
    font-size: 18px;
}


</style>