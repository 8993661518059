<template>
    <b-container fluid class="py-5">
        <b-row>
            <b-col>
                <b-card class="pb-12 barra-nuevos-productos" :style="cssVars">
                    <h4> {{$route.params.category}}</h4>
                </b-card>
            </b-col>
        </b-row>
        <b-row class="tipo-vistas-card justify-content-md-center my-3">
            <b-col cols="4" md="2">
                <b-button variant="light" @click="typeViewAction(1)" class="mr-2">
                    <b-icon icon="grid3x3-gap" > </b-icon>
                </b-button>
                <b-button variant="light" @click="typeViewAction(2)" class="mr-2">
                    <b-icon icon="grid" > </b-icon>
                </b-button>
                <b-button variant="light" @click="typeViewAction(3)">
                    <b-icon icon="list-ul" > </b-icon>
                </b-button>
            </b-col>  
            <b-col cols="2" md="1">
                <b-form-group>
                    <b-form-select
                    v-model="paginador.pageSize"
                    :options="pageSizeOptions"
                    @change="getItemsByCategory"
                    >
                    </b-form-select>
                </b-form-group>
            </b-col>
            <b-col cols="4" md="3">
                <b-form-group>
                    <b-form-select
                    v-model="paginador.order"
                    :options="orderOptions"
                    @change="getItemsByCategory"
                    >
                    </b-form-select>
                </b-form-group>
            </b-col>
        </b-row>
        <b-row class="only-mobile-display">
            <b-col>
                <b-form-group>
                    <b-form-select
                    v-model="paginador.pageSize"
                    :options="pageSizeOptions"
                    @change="getItemsByCategory"
                    >
                    </b-form-select>
                </b-form-group>
            </b-col>
            <b-col >
                <b-form-group>
                    <b-form-select
                    v-model="paginador.order"
                    :options="orderOptions"
                    @change="getItemsByCategory"
                    >
                    </b-form-select>
                </b-form-group>
            </b-col>
        </b-row>
        <hr>
        <b-row class="my-3">
            <b-col cols="12" class="justify-content-md-center"> 
                <div >
                    <v-pagination
                    v-model="paginador.page"
                    :length="paginador.total"
                    :total-visible="7"
                    circle
                    @input="changePage"
                    ></v-pagination>
                </div>
            </b-col>
        </b-row>
        <hr>
        <div>
            <div v-if="catalogo.length > 0 && displayItem === 1">
                <card-item :items="catalogo" @item-info="itemInfo($event)" @add-item="openAddModal($event)" />
            </div>
            <div v-else-if="catalogo.length > 0 && displayItem === 2">
                <card-item2 :items="catalogo" @item-info="itemInfo($event)" @add-item="openAddModal($event)" />
            </div>
            <div v-else-if="catalogo.length > 0 && displayItem === 3">
                <card-item3 :items="catalogo" @item-info="itemInfo($event)" @add-item="openAddModal($event)" />
            </div>
            <b-alert v-else variant="danger" show>
               Lo sentimos, no se han encontrado resultados para su busqueda.
            </b-alert>
        </div>
        <template v-if="catalogo.length > 0">
            <div class="text-end">
                <v-pagination
                    v-model="paginador.page"
                    :length="paginador.total"
                    :total-visible="7"
                    circle
                    @input="changePage"
                ></v-pagination>
            </div>
        </template>
        <addItem @close-modal="closeAddModal" :openModal="itemModal" :item="itemData"/>
    </b-container>
</template>

<script>
import { mapState, mapMutations} from 'vuex'
import addItem from '@/components/AddItemToCart/AddItem.vue'
import CardItem from '../components/CardItemBase.vue'
import CardItem2 from '../components/CardItem2.vue'
import CardItem3 from '../components/CardItem3.vue'
export default {
    data(){
        return{
            displayItem: 1,
            catalogo: [],
            paginador: {
                page: 1,
                total: 1,
                pageSize: 18,
                order: 0
            },
            itemModal: false,
            itemData: '',
            pageSizeOptions: [
                { value: 18, text: '18' },
                { value: 24, text: '24' },
                { value: 48, text: '48' }
            ],
            orderOptions: [
                { value: 0, text: 'Orden inicial' },
                { value: 1, text: 'Productos (A-Z)' },
                { value: 2, text: 'Productos (Z-A)' }
            ]
        }
    },
    components: {
        addItem,
        CardItem,
        CardItem2,
        CardItem3
    },
    computed: {
        ...mapState('configuration', ['background', 'opcionExistencia']),
        cssVars() {
            return {
                '--border-bottom': this.background
            };
        },
    },
    watch: {
        '$route.params.id': function () {
        this.getItemsByCategory()
        }
    },
    created: function () {
        this.getItemsByCategory()
    },
    methods: {
        ...mapMutations(['setLoading']),
        async getItemsByCategory(){
            this.setLoading(true)
            let categoryId = this.$route.params.id;
            let pageSize = this.paginador.pageSize;
            let pageNumber = this.paginador.page;
            let orderType = this.paginador.order;
            let dataRequest = { categoryId, pageSize, pageNumber, orderType};
            await this.$conectar.post('/articulos/categoria/search-items-empresa-category', dataRequest)
            .then(response =>{
                this.catalogo = response.data.itemsEmpresaLists;
                this.paginador.total = response.data.totalPages;
            })
            .catch(error => {
                console.log(error);
            })
            this.setLoading(false)
        },
        changePage(page){
            this.paginador.page = page;
            this.getItemsByCategory();
        },
        
        itemInfo(data){
            let itemName = data.nombre.replace(/\s+/g, '-').toLowerCase();
            this.$router.push({ name: 'itemEmpresaDetail', params:{ codigo: data.codigo, slug: itemName} });
        },
        openAddModal(item){
            this.itemModal = true;
            this.itemData = item;
        },
        closeAddModal(){
            this.itemModal = false;
            this.itemData = '';
        },
        typeViewAction(typeView){
            this.displayItem = typeView;
        }
           
        
    }
}
</script>

<style scoped>
.barra-nuevos-productos{
    background-color: #fffffc;
    background-image: linear-gradient(200deg, #fffffc 0%, #cac7c7 84%);
    border-bottom: 5px solid var(--border-bottom);
}

.only-mobile-display{
    display:none
}


@media screen and (max-width: 800px) {
    .tipo-vistas-card{
        display: none;
    }
    .only-mobile-display{
        display:initial
    }
}

</style>
